import HyperLogo from "./Images/HyperLogo.png";
import styled from "styled-components";
import { useState } from "react";
import { HiOutlineClipboardCopy } from "react-icons/hi";
const Main = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 5%;
  flex-direction: column;
  width: 40%;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
`;

const Box = styled.div`
  background-color: rgb(255, 255, 255);
  box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px 0px;
  border-radius: 4px;
  padding: 1em;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Image = styled.img`
  margin: 20px;
`;

const Input = styled.input`
  display: flex;
  width: 100%;
  padding: 0.5em;
  font-size: 0.875rem;
  line-height: 1.25;
  color: rgb(73, 80, 87);
  background-color: rgb(255, 255, 255);
  background-image: none;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  box-sizing: border-box;
  :focus {
    color: rgb(73, 80, 87);
    background-color: rgb(255, 255, 255);
    border-color: rgb(20, 236, 142);
    outline: none;
  }
`;

const Row = styled.div`
  display: flex;
  padding: 0.5em;
  width: 100%;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
`;

const Button = styled.div`
  display: flex;
  box-shadow: rgb(0 0 0 / 10%) 0px 1px 2px 0px;
  -webkit-box-align: center;
  align-items: center;
  padding: 0.5em;
  border-radius: 4px;
  -webkit-box-pack: center;
  justify-content: center;
  cursor: pointer;
  background-color: rgb(219, 10, 65);
  color: rgb(255, 255, 255);
  font-size: 0.8em;
  width: 40%;
  :hover {
    cursor: pointer;
    background-color: rgb(177, 8, 53);
  }
`;

const Icon = styled.div`
  margin-left: 20px;
  font-size: 20px;
  cursor: pointer;
  :hover {
    color: rgb(219, 10, 65);
  }
`;
function App() {
  const [data, setData] = useState("");
  const [encryptedData, setEncryptedData] = useState("");

  function encrypt(data_to_encrypt) {
    fetch("/encrypt", {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({ data: data_to_encrypt }),
    })
      .then((res) => res.text())
      .then((encryped_data) => {
        setEncryptedData(encryped_data);
      });
  }

  function copy() {
    // Get the text field
    var copyText = document.getElementById("e_data");

    // Copy the text inside the text field
    navigator.clipboard.writeText(copyText.innerText);
    var tooltip = document.getElementById("myTooltip");
    tooltip.innerHTML = "Copied";
  }

  return (
    <Main>
      <Image src={HyperLogo} alt="Hyper Logo" height="50px" />
      <Box>
        <Row>
          <Input
            type="password"
            placeholder="Enter text to encrypt"
            name="data"
            value={data}
            onChange={(e) => {
              setData(e.currentTarget.value);
            }}
            autoComplete="off"
          />
        </Row>
        <Row>
          <Button
            onClick={() => {
              encrypt(data);
            }}
          >
            Encrypt
          </Button>
        </Row>
        <Row>
          <div id="e_data">{encryptedData}</div>
          {encryptedData !== "" && (
            <Icon className="tooltip">
              <span class="tooltiptext" id="myTooltip">
                Copy to clipboard
              </span>
              <HiOutlineClipboardCopy
                onClick={() => {
                  copy();
                }}
              />
            </Icon>
          )}
        </Row>
      </Box>
    </Main>
  );
}

export default App;
